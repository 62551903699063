import { db, storage } from "../../components/Firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Emoji } from "../../components/Utils";
import { Link } from "gatsby";
import SeoSpace from "../../components/SeoSpace";
import { unescapeProcess } from "../../components/Utils";

const Visit = () => {
  const [ spaceHTML, setSpaceHTML ] = useState([]);
  const [ waitState, setWaitState ] = useState(false);

  useEffect(() => {
    initFunc();
    removeCanvas();
  }, []);

  function checkIventAddress(address: string) {
    const IVENT_ADDRESS = "MPRf7kFGucUsK6DdBr5qP2a74sKQFY7Rik";
    return address === IVENT_ADDRESS ? true : false;
  }

  async function initFunc() {
    
    const cut = window.location.href.indexOf("visit");
    const baseURL = location.href.slice(0, cut);
    const toURL = baseURL + "myspace?spacename=";
    const userCollectionRef = collection(db, "users");
    const snapshot = await getDocs(query(userCollectionRef, orderBy("update_at", "desc"))).then((querySnapshot) => { return querySnapshot });
    const spaceData = [];

    for(let i = 0; i < snapshot.size; i++) {

      const iventAddress = checkIventAddress(snapshot.docs[i].data().user_address);
      // if(iventAddress) continue;
    
      const unescapeSpaceName = snapshot.docs[i].data().space.map((r) => unescapeProcess(r.space_name));
      const encodeURISpaceName = snapshot.docs[i].data().space.map((r) => encodeURIComponent(r.space_name));
      const unscapeOwnerName = snapshot.docs[i].data().space.map((r) => unescapeProcess(r.owner_name));
      const ogpImageName = snapshot.docs[i].data().space.map((r) => r.ogp_image_name);
      const timeFromOgpImageName = ogpImageName.toString().slice(-17, -4);
      const userAddress = snapshot.docs[i].data().user_address;
      const link = toURL + encodeURISpaceName + "&addr=" + userAddress + "&time=" + timeFromOgpImageName;
      if(iventAddress) {
        spaceData.push(
          <div className="rounded-tl-[48px] rounded-br-[48px] h-fit mt-10 sm:mt-8 text-left transition duration-[250ms] ease-out hover:scale-105 overflow-hidden break-all border-solid border-2 border-black" key={ i }>
            <a href={ link } onClick={() => setWaitState(true)}>
              <img
                id={ "img" + i }
                className="w-full"
                src="../../images/ivent_01_ogp.png"
              />
              <div className="p-4 bg-gradient-to-br from-yellow-200 to-teal-500">
                <p className="text-2xl">{ unescapeSpaceName }</p>
                <div className="p-0">
                  {/* <p className="">{ unscapeDescription }</p> */}
                  <p className="mt-2">{ unscapeOwnerName }</p>
                  <p className="mt-2">{ userAddress }</p>
                </div>
              </div>
            </a>
          </div>
        )
      } else {

        spaceData.push(
          <div className="rounded-tl-[48px] rounded-br-[48px] h-fit mt-10 sm:mt-8 text-left transition duration-[250ms] ease-out hover:scale-105 overflow-hidden break-all border-solid border-2 border-black" key={ i }>
            <a href={ link } onClick={() => setWaitState(true)}>
              <img
                id={ "img" + i }
                className="w-full"
                src="../../images/image_loading.png"
              />
              <div className="p-4 bg-gradient-to-br from-white to-neutral-300 ">
                <p className="text-2xl">{ unescapeSpaceName }</p>
                <div className="p-0">
                  {/* <p className="">{ unscapeDescription }</p> */}
                  <p className="mt-2">{ unscapeOwnerName }</p>
                  <p className="mt-2">{ userAddress }</p>
                </div>
              </div>
            </a>
          </div>
        )
        await getSpaceImage(ogpImageName, i);
      }
    }
    setSpaceHTML(spaceData);
  }
  const WaitWindow = () => {
    if(!waitState) return <></>;
    return(
      <div id="error_message" className="z-10 animate-fadeIn_02 fixed bottom-0 right-0 w-full md:w-2/5 p-4">
        <div className="animate-pulse bg-gradient-to-r from-pink-300 via-indigo-300 to-yellow-200 text-white border-white border-double border-4 rounded-lg p-4 ">
          <p className="text-center">loading...</p>
        </div>
      </div>
    );
  }
  return(
    <>
      <SeoSpace />
      <WaitWindow />
      <div className="px-4 sm:px-20">
        <div id="menuField" className="text-center text-gray-900 flex justify-center content-center">
        {/* <div id="menuField" className="text-center"> */}
          <div className="w-full h-full px-4 py-12">
            <div className="py-20">
              <Link to="/space">
                <p className="font-light italic text-4xl sm:text-5xl">monanosu_space</p>
              </Link>
            </div>
            <div className="py-2 sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-8">
              { spaceHTML }
            </div>
          </div>
        </div>
        <div className="flex justify-center content-center py-20">
          <Link to="/space">
            <div className="px-16 py-20 bg-white border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px] mt-8 transition duration-[250ms] ease-out hover:opacity-30 hover:invert overflow-hidden">
              <p className="text-2xl sm:text-3xl">SPACE HOME
                <span className="text-5xl">  { Emoji.musiumEmoji }</span>
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

/**
 * Request Space Image
 * @param ogpImageName 
 * @param i 
 * @returns 
 */
function getSpaceImage(ogpImageName: string, i: number) {
  getDownloadURL(ref(storage, `ogp_images/${ogpImageName}`))
    .then((url) => {
      document.getElementById("img" + i).setAttribute("src", url);
    })
    .catch((e) => {
      getDownloadURL(ref(storage, `ogp_images/image_failed.png`))
        .then((url) => {
          document.getElementById("img" + i).setAttribute("src", url);
        })
    });
  return;
}

/**
 * When returning from the space, the canvas remaints, so delete it.
 */
function removeCanvas() {
  setTimeout(() => {
    const element = document.getElementById("canvas");
    if(element != null) { element.remove() }
  }, 100);
}

export default Visit;